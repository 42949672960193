import styled from '@emotion/styled';
import theme from '../../utils/theme';
import { mobileQuery, tabletQuery, laptopQuery, desktopQuery } from '../../utils/mediaqueries';

export const Container = styled.div({
  background: theme.colors.darkBlue,
  overflow: 'auto',

  [mobileQuery]: {
    height: '60%',
  },

  [laptopQuery]: {
    width: '48%',
  },

  [desktopQuery]: {
    width: '48%',
  },
});

export const Content = styled.div({
  [mobileQuery]: {
    margin: '20px',
    height: '75%',
  },

  [tabletQuery]: {
    margin: '50px',
  },

  [laptopQuery]: {
    margin: '15px 30px 0',
  },

  [desktopQuery]: {
    margin: '15px 30px 0',
  },
});

export const SocialButtons = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  alignContent: 'center',
  width: '100%',

  [tabletQuery]: {
    marginTop: '20px',
  },

  [laptopQuery]: {
    marginTop: '20px',
  },

  [desktopQuery]: {
    marginTop: '20px',
  },
});

export const TextContainer = styled.div({
  color: theme.colors.descriptionText,

  a: {
    color: theme.colors.orangeBackground,
    fontWeight: 'bold',
    textDecoration: 'none',

    '&:visited': {
      color: theme.colors.orangeBackground,
    },
  },

  '& > h1': {
    fontSize: '2rem',
  },

  '& > h2': {
    fontSize: '1.5rem',
  },

  [mobileQuery]: {
    height: '85%',
    margin: '0',
    overflow: 'auto',
  },

  [tabletQuery]: {
    fontSize: '1.2rem',
  },

  [desktopQuery]: {
    fontSize: '1.3rem',
  },
});
