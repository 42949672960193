import styled from '@emotion/styled';
import theme from '../../utils/theme';

import { mobileQuery, tabletQuery, laptopQuery, desktopQuery } from '../../utils/mediaqueries';

export const Container = styled.div({
  display: 'flex',
  width: '100%',
  height: '100vh',

  [mobileQuery]: {
    flexDirection: 'column',
  },

  [tabletQuery]: {
    flexDirection: 'column',
  },

  [laptopQuery]: {
    right: 0,
    position: 'fixed',
    width: '67%',
  },

  [desktopQuery]: {
    right: 0,
    position: 'fixed',
    width: '67%',
  },
});

export const BottomRightControls = styled.div(
  {
    [mobileQuery]: {
      padding: '35px',
    },

    [tabletQuery]: {
      padding: '50px',
    },

    [laptopQuery]: {
      padding: '30px',
    },

    [desktopQuery]: {
      padding: '30px',
    },
  },
  props => ({
    display: props.hideControls ? 'none' : 'block',
  }),
);

export const BottomLeftControls = styled.div(
  {
    [mobileQuery]: {
      padding: '35px',
    },

    [tabletQuery]: {
      padding: '50px',
    },

    [laptopQuery]: {
      padding: '30px',
    },

    [desktopQuery]: {
      padding: '30px',
    },
  },
  props => ({
    display: props.hideControls ? 'none' : 'block',
  }),
);

export const BottomCenterControl = styled.p(
  {
    color: theme.colors.mainText,

    [mobileQuery]: {
      marginBottom: '35px',
    },

    [tabletQuery]: {
      marginBottom: '50px',
      fontSize: '1.3rem',
    },

    [laptopQuery]: {
      marginBottom: '30px',
    },

    [desktopQuery]: {
      marginBottom: '25px',
      fontSize: '1.3rem',
    },
  },
  props => ({
    display: props.hideControls ? 'none' : 'block',
  }),
);
