const SOCIAL_BUTTONS = [
  {
    type: "facebook",
    href: "https://www.facebook.com/UTBZlin/",
  },
  {
    type: "twitter",
    href: "https://twitter.com/univerzitatbati/",
  },
  {
    type: "instagram",
    href: "https://www.instagram.com/utbzlin/",
  },
  {
    type: "youtube",
    href: "https://www.youtube.com/c/UniverzitaTomaseBatiVeZline",
  },
  {
    type: "linkedin",
    href: "https://www.linkedin.com/school/tomas-bata-university/",
  },
  {
    type: "spotify",
    href: "https://open.spotify.com/show/1dNqQuHMd9o1ns1mn8Ut7S",
  },
];

export default SOCIAL_BUTTONS;
