import React, { useEffect, useCallback } from 'react';
import { facultyDescriptions, facultyTitles, facultyImages } from '../seo/MainPageSeo';
import { createLink } from '../utils/functions';

import Card from '../components/card/Card';
import ExcerptWrapper from '../components/ExcerptWrapper';
import Header from '../components/Header';
import ImageWrapper from '../components/ImageWrapper';
import Layout from '../components/layout/Layout';
import ModuleCard from '../components/ModuleCard';
import NavExcerpt from '../components/NavExcerpt';
import NavMenu from '../components/NavMenu';
import Slideshow from '../components/slideshow/Slideshow';
import SlideShowContentWrapper from '../components/SlideshowContentWrapper';
import Seo from '../components/Seo';
import { GatsbyImage } from 'gatsby-plugin-image';
import { mobileQuery, tabletQuery } from '../utils/mediaqueries';

const imageStyles = {
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundColor: 'red',
  flex: 1,

  [mobileQuery]: {
    height: '40%',
    width: '100%',
  },

  [tabletQuery]: {
    height: '40%',
  },
};

const ExcerptDetailTemplate = ({ pageContext, uri }) => {
  const { excerptDetail, mainPageTitle, navExcerpts, studyLevel: studyLevels } = pageContext;

  const isStudyPage = uri.includes('studium');
  const isInstitute = uri.includes('institut');
  const hideSlideshowControls = excerptDetail.length < 2 && !isInstitute;
  const url = uri.split('/');

  const facultyKey = url[2];

  const studySeoTitle = facultyTitles[facultyKey];
  const studySeoDescription = facultyDescriptions[facultyKey];
  const studySeoImage = facultyImages[facultyKey];

  const metaTitle = isStudyPage ? studySeoTitle : '';
  const metaDescription = isStudyPage ? studySeoDescription : '';
  const metaImage = isStudyPage ? studySeoImage : '';

  const scrollIntoExcerpt = useCallback(() => {
    const options = {
      block: 'start',
      inline: 'center',
      behavior: 'smooth',
    };

    const selectedExcerpt = document.querySelector(`#${url[2]}`);

    selectedExcerpt.scrollIntoView(options);
  }, [url]);

  useEffect(() => {
    scrollIntoExcerpt();
    return scrollIntoExcerpt();
  }, [scrollIntoExcerpt]);

  const renderExcerpts = () =>
    navExcerpts.map(({ heading, description, thumbnail }) => (
      <NavExcerpt
        currentUrl={uri}
        description={description}
        heading={heading}
        id={createLink(heading)}
        key={heading}
        thumbnail={thumbnail}
        to={`/${createLink(mainPageTitle)}/${createLink(heading)}`}
      />
    ));

  const renderModulePage = () => (
    <SlideShowContentWrapper>
      <ImageWrapper background={studySeoImage}>
        <Header sideMargin backButton />
      </ImageWrapper>
      <ModuleCard studyLevels={studyLevels} currentUri={uri} heading="STUDIUM" />
    </SlideShowContentWrapper>
  );

  const renderDetail = () =>
    excerptDetail.map(({ title, background, text }) => (
      <SlideShowContentWrapper key={title}>
        {/* <ImageWrapper background={background}>
          <Header sideMargin backButton />
        </ImageWrapper> */}
        <GatsbyImage image={background.childImageSharp.gatsbyImageData} style={imageStyles} />
        <Card heading={title} text={text} showSocialButtons={uri.includes('socialni-site')} />
      </SlideShowContentWrapper>
    ));

  return (
    <>
      <Seo title={metaTitle} description={metaDescription} image={metaImage} />
      <Layout>
        <NavMenu hide uri={uri} />
        <ExcerptWrapper hide>{renderExcerpts()}</ExcerptWrapper>
        <Slideshow hideControls={hideSlideshowControls} section="navstiv-nas">
          {uri.includes('studium') && renderModulePage()}
          {renderDetail()}
        </Slideshow>
      </Layout>
    </>
  );
};

export default ExcerptDetailTemplate;
