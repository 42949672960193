import React from "react";

import styled from "@emotion/styled";
import theme from "../utils/theme";
import { laptopQuery, desktopQuery } from "../utils/mediaqueries";

import { createLink } from "../utils/functions";

import ModuleButton from "./ModuleButton";

const borderRadius = "20px";

const ModuleCardWrapper = styled.div({
  background: theme.colors.darkBlue,
  borderTopLeftRadius: borderRadius,
  borderTopRightRadius: borderRadius,
  height: "60%",

  [laptopQuery]: {
    width: "48%"
  },

  [desktopQuery]: {
    width: "48%"
  }
});

const ContentWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  marginTop: "10px",
  width: "100%"
});

const StyledHeading = styled.h1({
  color: theme.colors.mainText,
  fontSize: "25px",
  margin: 0,
  marginLeft: "35px",

  [laptopQuery]: {
    marginTop: "6px"
  },

  [desktopQuery]: {
    marginTop: "8px"
  }
});

const ModuleButtonWrapper = styled.div({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",

  [laptopQuery]: {
    width: "100%"
  }
});

const ModuleCard = ({ currentUri, heading, studyLevels }) => {
  const renderModuleButtons = () => (
    <ModuleButtonWrapper>
      {studyLevels.map(({ level }) => (
        <ModuleButton
          bordered={false}
          key={level}
          title={level}
          to={`${currentUri}/${createLink(level)}`}
        />
      ))}
    </ModuleButtonWrapper>
  );

  return (
    <ModuleCardWrapper>
      <ContentWrapper>
        <StyledHeading>{heading}</StyledHeading>
        {renderModuleButtons()}
      </ContentWrapper>
    </ModuleCardWrapper>
  );
};

export default ModuleCard;
