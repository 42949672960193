import {
  mobileQuery,
  tabletQuery,
  laptopQuery,
  desktopQuery,
} from '../utils/mediaqueries';

import styled from '@emotion/styled';

const SlideShowContentWrapper = styled.div({
  display: 'flex',
  height: '100vh',

  [mobileQuery]: {
    flexDirection: 'column',
  },

  [tabletQuery]: {
    flexDirection: 'column',
  },

  [laptopQuery]: {
    right: 0,
    width: '100%',
  },

  [desktopQuery]: {
    right: 0,
    width: '100%',
  },
});

export default SlideShowContentWrapper;
