import styled from '@emotion/styled';
import theme from '../utils/theme';
import { mobileQuery } from '../utils/mediaqueries';

const { mainText } = theme.colors;

const Heading = styled.h1({
  color: mainText,
  marginBottom: '1.5rem',

  [mobileQuery]: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
});

export default Heading;
