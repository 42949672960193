import styled from '@emotion/styled';
import { mobileQuery, tabletQuery, laptopQuery, desktopQuery } from '../utils/mediaqueries';

const ImageWrapper = styled.div(
  {
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',

    [mobileQuery]: {
      height: '40%',
      width: '100%',
    },

    [tabletQuery]: {
      height: '40%',
    },

    [laptopQuery]: {
      width: '52%',
    },

    [desktopQuery]: {
      width: '52%',
    },
  },
  ({ background }) => ({
    backgroundImage: `url(${background})`,
  }),
);

export default ImageWrapper;
