import React from "react";

import styled from "@emotion/styled";
import theme from "../utils/theme";

import { mobileQuery } from "../utils/mediaqueries";

import Icon from "../components/Icon";

const SocialButton = ({ type, href }) => {
  const SocialButtonWrapper = styled.div({
    marginRight: "20px",
    display: "flex",
    height: "98px",
    textDecoration: "none",
    flexDirection: "column",
    borderRadius: "5px",
    width: "100px",
    background: theme.colors.blue,
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    marginBottom: "20px",

    [mobileQuery]: {
      margin: "5px",
      height: "68px",
      width: "70px",
    },
  });

  const SocialButtonLayout = SocialButtonWrapper.withComponent("a");

  return (
    <SocialButtonLayout href={href} target="_blank">
      <Icon
        size="3x"
        color="white"
        iconName={["fab", type]}
        marginBottom="8px"
      />
    </SocialButtonLayout>
  );
};

export default SocialButton;
