import React, { useState, useEffect } from 'react';
import theme from '../../utils/theme';

import Carousel from 'nuka-carousel';
import Icon from '../Icon';

import { usePrevious } from '../../utils/hooks';
import { Container, BottomRightControls, BottomCenterControl, BottomLeftControls } from './styles';

const Slideshow = ({ children, hideControls, section }) => {
  const [slideIndex, setSlideIndex] = useState(0);

  const prevSection = usePrevious({ section });

  useEffect(() => {
    if (prevSection !== undefined && prevSection.section !== section) {
      setSlideIndex(0);
    }
  }, [setSlideIndex, prevSection, section]);

  const bottomRightControls = nextSlide => (
    <BottomRightControls hideControls={hideControls} onClick={nextSlide}>
      <Icon iconName="chevron-right" color="white" size="lg" />
    </BottomRightControls>
  );

  const bottomLeftControls = previousSlide => (
    <BottomLeftControls hideControls={hideControls} onClick={previousSlide}>
      <Icon iconName="chevron-left" color={theme.colors.mainText} size="lg" />
    </BottomLeftControls>
  );

  const bottomCenterControl = (currentSlide, slideCount) => (
    <BottomCenterControl hideControls={hideControls}>{`${(currentSlide += 1)}/${slideCount}`}</BottomCenterControl>
  );

  return (
    <Container>
      <Carousel
        dragging={false}
        enableKeyboardControls
        disableEdgeSwiping
        speed={800}
        slideIndex={slideIndex}
        afterSlide={slideIndex => setSlideIndex(slideIndex)}
        wrapAround
        renderBottomRightControls={({ nextSlide }) => bottomRightControls(nextSlide)}
        renderBottomLeftControls={({ previousSlide }) => bottomLeftControls(previousSlide)}
        renderBottomCenterControls={({ currentSlide, slideCount }) => bottomCenterControl(currentSlide, slideCount)}
        renderCenterLeftControls={() => null}
        renderCenterRightControls={() => null}
      >
        {children}
      </Carousel>
    </Container>
  );
};

export default Slideshow;
