import React from "react";

import styled from "@emotion/styled";
import theme from "../utils/theme";
import {
  mobileQuery,
  laptopQuery,
  desktopQuery,
  tabletQuery
} from "../utils/mediaqueries";

import { Link } from "gatsby";

const StyledNavlink = styled(Link)(
  {
    alignItems: "center",
    color: theme.colors.mainText,
    cursor: "pointer",
    display: "flex",
    height: "60px",
    justifyContent: "center",
    textAlign: "center",
    textDecoration: "none",
    transition: "background 500ms",
    width: "100%",

    "&:hover": {
      background: theme.colors.blue
    },

    [mobileQuery]: {
      padding: "8px 10px",
      width: "65%"
    },

    [tabletQuery]: {
      height: "80px",
      width: "200px"
    }
  },
  ({ padding, bordered, borderColor, sm }) => ({
    padding: padding ? padding : 0,
    border: bordered
      ? `1px solid ${borderColor ? borderColor : "black"}`
      : "none",

    [laptopQuery]: {
      height: sm ? "120px" : "60px",
      width: sm ? "30%" : "100%"
    },

    [desktopQuery]: {
      height: sm ? "120px" : "60px",
      width: sm ? "30%" : "100%"
    }
  })
);

const ModuleButton = ({
  borderColor,
  bordered = true,
  excerpts,
  padding,
  sm,
  title,
  to
}) => (
  <StyledNavlink
    padding={padding}
    borderColor={borderColor}
    bordered={bordered}
    sm={sm}
    state={{ excerpts }}
    to={to}
  >
    {title}
  </StyledNavlink>
);

export default ModuleButton;
