import React from 'react';
import ReactMarkdown from 'react-markdown';

import SOCIAL_BUTTONS from '../../constants/socialButtons';

import SocialButton from '../SocialButton';
import Heading from '../Heading';

import { Container, SocialButtons, Content, TextContainer } from './styles';

const Card = ({ heading, text, showSocialButtons }) => {
  const renderText = () => (
    <TextContainer>
      <ReactMarkdown children={text} />
    </TextContainer>
  );

  const renderSocialButtons = () => (
    <SocialButtons>
      {SOCIAL_BUTTONS.map(({ type, href }) => (
        <SocialButton type={type} href={href} />
      ))}
    </SocialButtons>
  );

  return (
    <Container>
      <Content>
        <Heading>{heading}</Heading>
        {!showSocialButtons ? renderText() : renderSocialButtons()}
      </Content>
    </Container>
  );
};

export default Card;
